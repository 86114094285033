/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, Global, jsx } from '@emotion/react';

import { NAVIGATION_BREAKPOINTS } from '@af/design-system-docs-ui';
import { HORIZONTAL_GLOBAL_NAV_HEIGHT } from '@atlaskit/atlassian-navigation';
import cssReset from '@atlaskit/css-reset';
import { N30 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

/**
 * CSS Reset + general global styling hacks for all of Design System Docs
 */
const GlobalStyles = () => (
	<Global
		// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression, @atlaskit/ui-styling-standard/no-imported-style-values, @repo/internal/react/no-css-string-literals -- Ignored via go/DSP-18766
		styles={css`
			${
				// eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values
				cssReset
			}
			* {
				box-sizing: inherit;
			}
			body,
			html {
				box-sizing: border-box;
				${
					/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''
				}
				scroll-padding-top: calc(${HORIZONTAL_GLOBAL_NAV_HEIGHT}px + ${token('space.200', '16px')});
				/* TODOS:
          Add smooth scrolling back in. Refer to ticket DST-2276.
          The navbar scrolling uses scroll-padding-top. This is currently not supported by Safari 11
          and is a known bug - looks like it will be released in the next version. Refer to ticket DST-2433.
          https://github.com/mdn/browser-compat-data/issues/8887
          https://css-tricks.com/fixed-headers-on-page-links-and-overlapping-content-oh-my/
          https://bugs.webkit.org/show_bug.cgi?id=179379
        */
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}

			img {
				max-width: 100%;
			}

			// CSS Reset sets marginTop of elements to 0 using first-child, which doesn't work with SSR. This override avoid margins jumping after SSR
			:first-child:is(p, ul, ol, dl, h1, h2, h3, h4, h5, h6, blockquote, pre, form, table),
			style:first-child + :is(p, ul, ol, dl, h1, h2, h3, h4, h5, h6, blockquote, pre, form, table) {
				margin-top: 0;
			}

			#___gatsby {
				isolation: isolate; // Hack to start new stacking context for content to prevent layering issues with portals
			}

			#layout_left-sidebar {
				border-right: 1px solid ${token('color.border', N30)};
				flex-shrink: 0;
				display: grid; // hack to fix height: 100% layout issue in LeftSidebarWithoutResize
			}

			#layout_left-sidebar ~ #main footer {
				text-align: left;
			}

			#main {
				min-height: calc(100vh - var(--topNavigationHeight));
			}

			@font-face {
				font-family: 'Charlie Display';
				src:
					url('/assets/CharlieDisplay-Regular.woff2') format('woff2'),
					url('/assets/CharlieDisplay-Regular.woff') format('woff');
				font-weight: 400;
				font-style: normal;
				font-display: swap;
			}

			@font-face {
				font-family: 'Charlie Display';
				src:
					url('/assets/CharlieDisplay-Semibold.woff2') format('woff2'),
					url('/assets/CharlieDisplay-Semibold.woff') format('woff');
				font-weight: 600;
				font-style: normal;
				font-display: swap;
			}

			${
				// eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values
				NAVIGATION_BREAKPOINTS.MOBILE
			} {
				:root {
					/**
           * TODO: Remove use of !important.
           * Page layout applies this as an inline style
           * to html for draggable resize areas...
           */
					--topNavigationHeight: 3rem !important;
				}
			}
		`}
	/>
);

export default GlobalStyles;
